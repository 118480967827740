'use strict';

var _require = require('./lib.js'),
    flag = _require.flag,
    name = _require.name,
    code = _require.code,
    countries = _require.countries;

module.exports = {
  flag: flag,
  name: name,
  code: code,
  countries: countries
};