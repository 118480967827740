import React from 'react';
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
} from 'react-admin';

import EmailRecipientsField from './components/email_recipient/email_recipients_field';
import { CountryField } from './components/country';

export const ActionCtaEmailShow = () => (
  <Show>
    <SimpleShowLayout>
      <ReferenceField source="actionId" reference="actions" link="show" />
      <DateField source="createdAt" showTime />
      <TextField source="updatedAt" />
      <CountryField />
      <EmailRecipientsField source="to" label="" />
      <EmailRecipientsField source="cc" label="" />
      <EmailRecipientsField source="bcc" label="" />
      <TextField source="subject" />
      <TextField source="body" component="pre" style={{ whiteSpace: 'pre-wrap' }} />
      <hr />
      <strong></strong>
      <FunctionField
        render={(resource: any) => {
          return (
            <>
              <Labeled label="English summary">
                <TextField source="summaryEN" />
              </Labeled>
              <a
                href={`#/action_cta_emails/${resource.id}/edit`}
                style={{ marginLeft: '1em' }}
              >
                edit
              </a>
            </>
          );
        }}
      />
      <FunctionField
        render={(resource: any) => {
          return (
            <>
              <Labeled label="French summary">
                <TextField source="summaryFR" />
              </Labeled>
              <a
                href={`#/action_cta_emails/${resource.id}/edit`}
                style={{ marginLeft: '1em' }}
              >
                edit
              </a>
            </>
          );
        }}
      />
    </SimpleShowLayout>
  </Show>
);
