import * as React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  Labeled,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  required,
} from 'react-admin';
import { countries } from 'countries-list';
import states from 'states-us';

import Grammarly from '@components/grammarly';
import Callout from '@components/callout';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { appPages } from '../action_ctas/app_page/components/app_page_select_input';

import { NotificationsPreview } from './components/notifications_preview';
import { BadgeEmojiSelectInput } from './components/badge_emojis';

const MAX_TITLE_LENGTH = 30;
const MAX_DESCRIPTION_LENGTH = 100;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const InAppNotificationCreate = () => {
  const imageUrl =
    'https://res.cloudinary.com/hsiz9ovy1/image/upload/v1694737404/in_app_notifications/app-ios_bdz8wz.png';

  /**
   * Select the country
   */
  const countrySelect = ({ formData }: any) => {
    if (formData.audience !== 'country') {
      return;
    }

    const countriesChoices = Object.entries(countries).map(([countryCode, country]) => ({
      id: countryCode,
      name: `${country.name} (${countryCode})`,
    }));

    return (
      <AutocompleteInput
        source="countryCode"
        choices={countriesChoices}
        emptyText="Choose a country"
        validate={[required()]}
        fullWidth
      />
    );
  };

  /**
   * Select the state if the country is US
   */
  const stateSelect = ({ formData }: any) => {
    if (formData.audience !== 'country' || formData.countryCode !== 'US') {
      return;
    }

    const stateChoices = Object.entries(states).map(([_, state]) => ({
      id: state.name,
      name: `${state.name} (${state.abbreviation})`,
    }));

    return (
      <AutocompleteInput
        source="stateName"
        choices={stateChoices}
        emptyText="All"
        label="US State"
        fullWidth
      />
    );
  };

  const usersSelect = ({ formData }: any) => {
    if (formData.audience !== 'users') {
      return;
    }
    return (
      <ReferenceArrayInput
        source="userIds"
        reference="users"
        sort={{ field: 'username', order: 'ASC' }}
      >
        <AutocompleteArrayInput
          label="Search by username"
          filterToQuery={search => ({ username: search })}
          fullWidth
          optionText={choice => choice.username}
          validate={[required()]}
        />
      </ReferenceArrayInput>
    );
  };

  return (
    <Create actions={CreateActions}>
      <SimpleForm>
        {/* Audience */}
        <strong>Choose the audience</strong>
        <SelectInput
          source="audience"
          choices={[
            { id: 'all', name: 'All users' },
            { id: 'country', name: 'Users form a specific country 🌎' },
            { id: 'users', name: 'Specific users 👥' },
          ]}
          defaultValue="all"
          defaultChecked
          fullWidth
          required
        />
        <FormDataConsumer>{countrySelect}</FormDataConsumer>
        <FormDataConsumer>{stateSelect}</FormDataConsumer>
        <FormDataConsumer>{usersSelect}</FormDataConsumer>

        {/* Destination */}
        <br />
        <strong>Where will this notification be displayed?</strong>
        <Callout>
          <div>
            By default, <strong>In-App Notification</strong> will be displayed in
            audience' <strong>Notification Center</strong> only.
          </div>
          <div>
            You can choose to also send it as a <strong>Push Notification</strong> and/or
            as <strong>In-App Toast Notification</strong>.
          </div>
        </Callout>
        <BooleanInput source="sendPushNotification" defaultValue={true} />
        <BooleanInput source="displayAsToast" defaultValue={true} />

        {/* General */}
        <br />
        <strong>General</strong>
        <Labeled label="Image displayed in Notification Center">
          <img src={imageUrl} style={{ width: 50, borderRadius: 50 }} />
        </Labeled>
        <BadgeEmojiSelectInput />

        <RadioButtonGroupInput
          source="associatedAction"
          choices={[
            { id: 'none', name: 'Do nothing' },
            { id: 'appPage', name: 'Open a screen inside chilli' },
            { id: 'externalLink', name: 'Open a webpage in browser' },
          ]}
          defaultValue="none"
          row={false}
          required
        />
        <FormDataConsumer>
          {({ formData }) =>
            (formData.associatedAction === 'appPage' && (
              <>
                <SelectInput
                  source="appPage"
                  choices={appPages}
                  fullWidth
                  required
                  label="Choose a screen to open"
                />
                {formData.appPage &&
                  appPages.find(page => page.id === formData.appPage)?.children}
              </>
            )) ||
            (formData.associatedAction === 'externalLink' && (
              <TextInput
                source="externalLink"
                type="url"
                fullWidth
                label="Write a valid URL"
                helperText="255 characters limit"
                inputProps={{ maxLength: 255 }}
                validate={[required(), maxLength(255)]}
              />
            ))
          }
        </FormDataConsumer>

        {/* Copy */}
        <br />
        <strong>Copy in 🇺🇸</strong>
        <Grammarly>
          <TextInput
            source="titleEN"
            label="Title EN"
            fullWidth
            helperText="30 characters limit, 25 max recommended."
            validate={[required(), maxLength(MAX_TITLE_LENGTH)]}
            inputProps={{ maxLength: MAX_TITLE_LENGTH }}
          />
        </Grammarly>
        <Grammarly>
          <TextInput
            source="descriptionEN"
            label="Description EN"
            fullWidth
            helperText="100 characters limit, 75 max recommended."
            validate={[required(), maxLength(MAX_DESCRIPTION_LENGTH)]}
            inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
          />
        </Grammarly>

        <br />
        <strong>Copy in 🇫🇷</strong>
        <TextInput
          source="titleFR"
          label="Title FR"
          fullWidth
          helperText="30 characters limit, 25 max recommended."
          validate={[required(), maxLength(MAX_TITLE_LENGTH)]}
          inputProps={{ maxLength: MAX_TITLE_LENGTH }}
        />
        <TextInput
          source="descriptionFR"
          label="Description FR"
          fullWidth
          helperText="100 characters limit, 75 max recommended."
          validate={[required(), maxLength(MAX_DESCRIPTION_LENGTH)]}
          inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
        />

        <NotificationsPreview imageUrl={imageUrl} />
      </SimpleForm>
    </Create>
  );
};
