import React from 'react';
import { countries } from 'countries-list';
import { Labeled, SelectField } from 'react-admin';

import Callout from '@components/callout';
import CountryInput from '@components/inputs/country_input';

const countriesChoices = Object.entries(countries).map(([countryCode, country]) => ({
  id: countryCode,
  name: `${country.name} (${countryCode})`,
}));

export const CountryField = () => (
  <Labeled label="Send to users from">
    <SelectField source="country" choices={countriesChoices} emptyText="Any country" />
  </Labeled>
);

export const CountrySelect = () => (
  <>
    <Callout emoji="🌎" backgroundColor="grey">
      If you want this email to be sent by users from a specific country only, select it
      here.
      <br />
      Otherwise, the email will be sent by users from any country.
    </Callout>
    <CountryInput source="country" fullWidth emptyText="All countries" />
  </>
);
