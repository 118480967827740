import React, { useEffect, useMemo, useState } from 'react';
import { Button, useDataProvider, useRecordContext, useRefresh } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import PublishIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from 'moment-timezone';
import { ActionType } from 'types/action';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { dateFormatter, durationFormatter } from '@services/date';
import TodoChecklist from '@components/todo_checklist';
import Callout from '@components/callout';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { expiresInValues } from '@models/actions/components/expires_in';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const PublishCard = () => {
  const action: ActionType = useRecordContext();
  const dataprovider = useDataProvider();
  const refresh = useRefresh();
  const {
    isSuperAdmin,
    isCampaignAdmin,
    canUpdate: canUpdateFromRBP,
  } = useRoleBasedPermissions();
  const canEdit: boolean = useMemo(() => canUpdateFromRBP('actions'), [canUpdateFromRBP]);

  const [areOwnersDefined, setAreOwnersDefined] = useState(false);
  const [isPointsDefined, setIsPointsDefined] = useState(false);
  const [isCTADefined, setIsCTADefined] = useState(false);
  const [isDescriptionENDefined, setIsDescriptionENDefined] = useState(false);
  const [isDescriptionFRDefined, setIsDescriptionFRDefined] = useState(false);
  const [isUsersObjectiveDefined, setIsUsersObjectiveDefined] = useState(false);
  const [areCountriesDefined, setAreCountriesDefined] = useState(false);
  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    setAreOwnersDefined(!!action?.owners?.length);
    setIsPointsDefined(!!action?.points);
    setIsCTADefined(!!action?.ctaType);
    setIsDescriptionENDefined(!!action?.descriptionEN);
    setIsDescriptionFRDefined(!!action?.descriptionFR);
    setIsUsersObjectiveDefined(!!action?.usersObjective);
    setAreCountriesDefined(!!action?.availableCountries?.length);
  }, [action]);

  const isReadyToPublish = useMemo(() => {
    if (isSuperAdmin) {
      return true;
    }
    return (
      areOwnersDefined &&
      isPointsDefined &&
      isCTADefined &&
      isDescriptionENDefined &&
      isDescriptionFRDefined &&
      isUsersObjectiveDefined &&
      areCountriesDefined
    );
  }, [
    isSuperAdmin,
    areOwnersDefined,
    isPointsDefined,
    isCTADefined,
    isDescriptionENDefined,
    isDescriptionFRDefined,
    isUsersObjectiveDefined,
    areCountriesDefined,
  ]);

  /**
   * According to the user's role, the minimum date to publish an action is different.
   * @returns {string}
   */
  const minDatePublishable = useMemo(() => {
    if (isSuperAdmin) {
      return moment().subtract(2, 'day').format('YYYY-MM-DDTHH:mm');
    }
    if (isCampaignAdmin) {
      return moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm');
    }
    return moment().add(2, 'day').format('YYYY-MM-DDTHH:mm');
  }, [isSuperAdmin, isCampaignAdmin]);

  useEffect(() => {
    console.log('minDatePublishable', minDatePublishable);
  }, [minDatePublishable]);

  const publishedInThePast = useMemo(() => {
    return (
      !!action?.publishedAt && moment(action?.publishedAt).diff(moment(), 'days') <= 0
    );
  }, [action?.publishedAt]);

  /**
   * @returns list of missing properties
   */
  const MissingPropertiesBlock = () => {
    const messages = [];
    if (!areOwnersDefined) {
      messages.push('At least an owner');
    }
    if (!isPointsDefined) {
      messages.push('Some points');
    }
    if (!isCTADefined) {
      messages.push('A Call To Action');
    }
    if (!isDescriptionENDefined) {
      messages.push('An English description');
    }
    if (!isDescriptionFRDefined) {
      messages.push('A French description');
    }
    if (!isUsersObjectiveDefined) {
      messages.push('An objective to reach');
    }
    if (!areCountriesDefined) {
      messages.push('Assign it to at least one country');
    }
    if (messages.length === 0) {
      if (action.publishedAt) {
        return <></>;
      }
      return (
        <div
          style={{
            fontSize: FontStyle.sizeSmall,
            fontWeight: 500,
            color: Colors.Magenta.primary,
          }}
        >
          Ready to be published!
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          fontSize: FontStyle.sizeVerySmall,
          fontWeight: 500,
          color: Colors.Red.primary,
        }}
      >
        <div>Before publishing, you first need to define:</div>
        <ul style={{ margin: SpacingStyle[4] }}>
          {messages.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ul>
      </div>
    );
  };

  const UnpublishAction = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
      const confirmed = window.confirm(
        'Are you sure you want to unpublish this action?\nIt will be removed from the app.'
      );
      if (!confirmed) {
        return;
      }
      try {
        setIsLoading(true);
        await dataprovider.update('actions', {
          id: action.id,
          data: {
            publishedAt: null,
          },
        } as any);
        await refresh();
      } finally {
        setIsLoading(false);
      }
    };
    if (!action?.publishedAt) {
      return <></>;
    }
    return (
      <Button
        variant="outlined"
        onClick={handleClick}
        label="Unpublish"
        endIcon={<EventBusyIcon style={{ width: '.8em' }} />}
        style={{
          fontSize: FontStyle.sizeVerySmall,
        }}
        color="error"
        disabled={isLoading}
      />
    );
  };

  const PublishedBlock = () => {
    const isPublishedAtInThePast = new Date(action?.publishedAt) < new Date();
    return (
      <>
        <div style={{ fontSize: FontStyle.sizeSmall, fontWeight: '500' }}>
          {isPublishedAtInThePast ? 'Was published on' : 'Will be published on'}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeBig,
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          {dateFormatter(action?.publishedAt, { short: true, withTime: true })}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeVerySmall,
            fontWeight: 500,
            color: Colors.Grey[500],
          }}
        >
          ({userTimezone})
        </div>
        <Callout emoji="🕰️" backgroundColor={Colors.Grey[25]}>
          <strong>Users will see this action at the following times:</strong>
          <table>
            <tbody>
              <tr>
                <td>
                  • <strong>Los Angeles</strong>
                </td>
                <td>
                  {moment(action?.publishedAt)
                    .tz('America/Los_Angeles')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>NYC</strong>
                </td>
                <td>
                  {moment(action?.publishedAt)
                    .tz('America/New_York')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>London</strong>
                </td>
                <td>
                  {moment(action?.publishedAt)
                    .tz('Europe/London')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>Paris</strong>
                </td>
                <td>
                  {moment(action?.publishedAt).tz('Europe/Paris').format('dddd @ hh:mma')}
                </td>
              </tr>
              <tr>
                <td>
                  • <strong>Sydney</strong>
                </td>
                <td>
                  {moment(action?.publishedAt)
                    .tz('Australia/Sydney')
                    .format('dddd @ hh:mma')}
                </td>
              </tr>
            </tbody>
          </table>
        </Callout>
      </>
    );
  };

  const ExpiredInBlock = () => {
    const expiringDate = moment(action?.publishedAt).add(action?.expiresIn || 0, 'days');
    const expiringDiffDays = expiringDate.diff(moment(), 'days');
    return (
      <>
        <div style={{ fontSize: FontStyle.sizeSmall, fontWeight: '500' }}>
          {expiringDiffDays === 0 && 'Action expires today'}
          {expiringDiffDays > 0 && 'Action expires in'}
          {expiringDiffDays < 0 && 'Action expired on'}
        </div>
        <div
          style={{
            fontSize: FontStyle.sizeMedium,
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          {expiringDiffDays <= 0
            ? dateFormatter(expiringDate.format(), { short: true })
            : durationFormatter(expiringDiffDays)}
        </div>
      </>
    );
  };

  if (!action) {
    return <></>;
  }

  return (
    <Card
      style={{
        gridColumn: 'span 2',
      }}
    >
      <CardHeader
        avatar={<PublishIcon />}
        title="Publish"
        subheader="When will this action be available and for how long?"
      />
      <CardContent>
        <Callout emoji="👇" backgroundColor={'grey'}>
          These dates will determine from when to when the action will be available in the
          app.
        </Callout>
        {/* publishedAt */}
        <TodoChecklist
          checked={!!action?.publishedAt}
          label="Set a publication date"
          cta={
            canEdit && (
              <div style={{ display: 'flex' }}>
                <UpdateFieldDialog
                  resource="actions"
                  record={action}
                  type="date"
                  field="publishedAt"
                  formatter={date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')}
                  minDate={minDatePublishable}
                  buttonLabel={action.publishedAt ? 'Edit' : 'Publish'}
                  buttonVariant="outlined"
                  disabled={
                    !isReadyToPublish || (!!action?.publishedAt && publishedInThePast)
                  }
                  hint={
                    !isSuperAdmin && !isCampaignAdmin
                      ? 'You can publish your action in 2 days, it will be under review by our team!'
                      : ''
                  }
                />
                <UnpublishAction />
              </div>
            )
          }
        >
          {action.publishedAt && <PublishedBlock />}
          <MissingPropertiesBlock />
        </TodoChecklist>

        {/* expiresIn */}
        {action.publishedAt && (
          <TodoChecklist
            checked={!!action?.expiresIn}
            label="Set an expiration date"
            cta={
              canEdit && (
                <UpdateFieldDialog
                  resource="actions"
                  record={action}
                  type="select"
                  selectOptions={expiresInValues(isSuperAdmin || isCampaignAdmin)}
                  field="expiresIn"
                  buttonLabel="Edit"
                  buttonVariant="outlined"
                />
              )
            }
          >
            <ExpiredInBlock />
          </TodoChecklist>
        )}
      </CardContent>
    </Card>
  );
};

export default PublishCard;
