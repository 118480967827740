import { ResizeFillAction } from "./ResizeFillAction.js";
/**
 * @description Defines how to crop-limit-fill an asset
 * @extends Actions.Resize.ResizeFillAction
 * @memberOf Actions.Resize
 * @see Visit {@link Actions.Resize| Resize} for examples
 */
class ResizeLimitFillAction extends ResizeFillAction {
}
export { ResizeLimitFillAction };
