import React, { useEffect, useMemo, useState } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  EmailField,
  FunctionField,
  ImageField,
  ReferenceField,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { UserType } from 'types/user';
import moment from 'moment-timezone';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import { PhoneNumberField } from '@components/phone_number_field';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import { UserAvatar } from '../user_avatar';

const ProfileTab = () => {
  const user = useRecordContext<UserType>();
  const { canCreate, canUpdate, isSuperAdmin, isCampaignAdmin } =
    useRoleBasedPermissions();
  const [commandCenterRolesOptions, setCommandCenterRolesOptions] = useState<any>([]);

  const canEditCommandCenterRole = useMemo(() => {
    if (!canUpdate('users')) {
      return false;
    }
    if (isSuperAdmin) {
      return true;
    }
    if (isCampaignAdmin) {
      return !!(
        user?.commandCenterRole &&
        (user.commandCenterRole >= 2 || user?.commandCenterRole === -1)
      );
    }
    return false;
  }, [canUpdate, isSuperAdmin, isCampaignAdmin, user]);

  const commandCenterRoleHumanized = useMemo(() => {
    if (user?.commandCenterRole === 0) {
      return 'Super Admin';
    } else if (user?.commandCenterRole === 1) {
      return 'Campaign Admin';
    } else if (user?.commandCenterRole === 2) {
      return 'Campaign Manager';
    } else if (user?.commandCenterRole === 3) {
      return 'Product';
    } else if (user?.commandCenterRole === 4) {
      return 'Read only';
    }
    return 'No access';
  }, [user]);

  useEffect(() => {
    if (isSuperAdmin) {
      setCommandCenterRolesOptions([
        { id: -1, name: 'No access' },
        { id: 0, name: 'Super Admin' },
        { id: 1, name: 'Campaign Admin' },
        { id: 2, name: 'Campaign Manager' },
        { id: 3, name: 'Product' },
        { id: 4, name: 'Read only' },
      ]);
    } else if (isCampaignAdmin) {
      setCommandCenterRolesOptions([
        { id: -1, name: 'No access' },
        { id: 2, name: 'Campaign Manager' },
        { id: 4, name: 'Read only' },
      ]);
    }
  }, [isSuperAdmin, isCampaignAdmin]);

  if (!user) {
    return;
  }

  return (
    <div style={styles.container}>
      <div style={styles.signUpInfo}>
        <div style={styles.df}>
          <div style={styles.label}>Signed up with:</div>
          <PhoneNumberField source="phoneNumber" />

          {user.appleUserEmail && (
            <AppleIcon style={{ fontSize: '1em', color: Colors.Grey.primary }} />
          )}
          <EmailField source="appleUserEmail" />
          {user.googleUserEmail && (
            <GoogleIcon style={{ fontSize: '1em', color: Colors.Grey.primary }} />
          )}
          <EmailField source="googleUserEmail" />
        </div>

        <div style={styles.df}>
          <div style={styles.label}>- Created at:</div>
          {user.createdAt &&
            moment(user.createdAt).fromNow() +
              ' (' +
              moment(user.createdAt).format('MMMM Do YYYY, h:mm:ss a') +
              ')'}
        </div>

        <div style={styles.df}>
          <div style={styles.label}>- Onboarded at:</div>
          {user.onboardedAt &&
            moment(user.onboardedAt).fromNow() +
              ' (' +
              moment(user.onboardedAt).format('MMMM Do YYYY, h:mm:ss a') +
              ')'}
        </div>

        <div style={styles.df}>
          <div style={styles.label}>- Last connected at:</div>
          {user.lastConnectedAt &&
            moment(user.lastConnectedAt).fromNow() +
              ' (' +
              moment(user.lastConnectedAt).format('MMMM Do YYYY, h:mm:ss a') +
              ')'}
        </div>
      </div>

      {/* Avatar */}
      <UserAvatar width={120} />
      <ImageField source="avatarNoBackgroundUrl" title="avatar" />

      {/* Firstname, lastname */}
      <FunctionField
        render={(user: UserType) => (
          <strong>
            {user.firstname || 'no firstname'} {user.lastname || 'no lastname'}
          </strong>
        )}
      />

      {/* Username / genre */}
      <FunctionField
        render={(user: UserType) => (
          <>
            @{user.username || 'no username'} ·{' '}
            {user?.gender?.toLowerCase() || 'undefined'}
          </>
        )}
      />

      {/* Location */}
      <FunctionField
        label="Location"
        render={(record: UserType) => {
          const { country, region, city, isLocationVisible } = record;
          return (
            '📍' +
            [city, region, country].filter(Boolean).join(', ') +
            ' ' +
            (isLocationVisible ? '' : '(hidden)')
          );
        }}
      />

      {/* Socials */}
      {user?.facebookHandle && <UrlField source="facebookHandle" label="Facebook URL" />}
      {user?.instagramHandle && (
        <UrlField source="instagramHandle" label="Instagram URL" />
      )}
      {user?.linkedinHandle && <UrlField source="linkedinHandle" label="Linkedin URL" />}
      {user?.snapchatHandle && <UrlField source="snapchatHandle" label="Snapchat URL" />}
      {user?.tiktokHandle && <UrlField source="tiktokHandle" label="Tiktok URL" />}
      {user?.twitterHandle && <UrlField source="twitterHandle" label="Twitter URL" />}
      {user?.mastodonHandle && <UrlField source="mastodonHandle" label="Mastodon URL" />}
      {user?.blueskyHandle && <UrlField source="blueskyHandle" label="Bluesky URL" />}
      {user?.websiteUrl && <UrlField source="websiteUrl" label="Website URL" />}

      {/* About me */}
      <div style={styles.profileCard}>
        <div style={styles.profileCardTitle}>about me</div>
        {user.occupation || 'no occupation'}
        <hr
          style={{
            height: 1,
            width: '100%',
            backgroundColor: Colors.Grey[100],
            border: 'none',
          }}
        />
        {user.bio || 'no bio'}
      </div>

      {/* Skills */}
      <div style={styles.profileCard}>
        <div style={styles.profileCardTitle}>skills</div>
        <div style={styles.df}>
          {user.profileSkills?.map(skill => (
            <a
              href={`#/users_profile_skills/${skill.userProfileSkillId}/show`}
              style={styles.tag}
              key={skill.id}
            >
              {skill.emoji} {skill.label}
            </a>
          ))}
          {canCreate('users_profile_skills') && (
            <a
              href={`#/users_profile_skills/create?source={"userId":"${user.id}"}`}
              style={styles.tag}
            >
              + add
            </a>
          )}
        </div>
      </div>

      {/* Causes */}
      <div style={styles.profileCard}>
        <div style={styles.profileCardTitle}>causes</div>
        <ArrayField source="causes" label={false}>
          <Datagrid bulkActionButtons={false}>
            <ReferenceField source="id" reference="causes" link="show" label={false} />
          </Datagrid>
        </ArrayField>
      </div>

      {/* Tags */}
      <div style={styles.profileCard}>
        <div style={styles.profileCardTitle}>tags</div>
        <div style={styles.df}>
          {user.profileTags?.map(tag => (
            <a
              href={`#/users_profile_tags/${tag.userProfileTagId}/show`}
              style={styles.tag}
              key={tag.id}
            >
              {tag.emoji} {tag.label}
            </a>
          ))}
          {canCreate('users_profile_tags') && (
            <a
              href={`#/users_profile_tags/create?source={"userId":"${user.id}"}`}
              style={styles.tag}
            >
              + add
            </a>
          )}
        </div>
      </div>

      {/* Prompts */}
      <div style={{ display: 'flex', gap: SpacingStyle.normal, flexWrap: 'wrap' }}>
        {user.profilePrompts?.map((prompt, index) => (
          <div key={prompt.id} style={styles.profileCard}>
            <div style={styles.profileCardTitle}>
              prompt {index + 1}
              {canUpdate('users_profile_prompts') && (
                <a href={`#/users_profile_prompts/${prompt.userProfilePromptId}`}>edit</a>
              )}
            </div>
            <strong>
              <small>{prompt.questionEN}</small>
            </strong>
            {prompt.answer}
          </div>
        ))}
        {/* create a prompt */}
        {canCreate('users_profile_prompts') &&
          user.profilePrompts &&
          user.profilePrompts.length < 3 && (
            <Button
              href={`#/users_profile_prompts/create?source={"userId":"${user.id}"}`}
              label="+ answer a prompt"
            />
          )}
      </div>

      {/* Medias */}
      <div style={{ display: 'flex', gap: SpacingStyle.normal, flexWrap: 'wrap' }}>
        {user.profileMedias?.map((media, index) => (
          <div key={media.id} style={styles.profileCard}>
            <div style={styles.profileCardTitle}>
              media {index + 1}
              {canUpdate('users_profile_medias') && (
                <a href={`#/users_profile_medias/${media.id}`}>edit</a>
              )}
            </div>
            <strong>
              <small>{media.tagline}</small>
            </strong>
            <a href={media.url} target="_blank" rel="noreferrer">
              {media.type === 'IMAGE' && (
                <img src={media.url} alt={media.tagline} style={styles.media} />
              )}
              {media.type === 'VIDEO' && (
                <video src={media.url} controls style={styles.media} />
              )}
            </a>
          </div>
        ))}
      </div>

      <div style={styles.signUpInfo}>
        <strong>Onboarding info</strong>
        <div style={styles.df}>
          <div style={styles.label}>- Referrer:</div>
          <TextField source="referrer" />
        </div>
        <FunctionField
          render={(record: any) => {
            if (!record.onboardingResponses) {
              return;
            }
            return (
              <ul>
                {Object.keys(record.onboardingResponses).map(key => {
                  if (typeof record.onboardingResponses[key] === 'string') {
                    return (
                      <li key={key}>
                        <strong>{key}:</strong> {record.onboardingResponses[key]}
                      </li>
                    );
                  } else if (Array.isArray(record.onboardingResponses[key])) {
                    return (
                      <li key={key}>
                        <strong>{key}:</strong>
                        <ul>
                          {record.onboardingResponses[key].map((item: string) => (
                            <li key={item}>{item}</li>
                          ))}
                        </ul>
                      </li>
                    );
                  }
                })}
              </ul>
            );
          }}
        />
      </div>

      <div style={styles.signUpInfo}>
        <strong>Other</strong>

        <div style={styles.df}>
          <div style={styles.label}>- Locale:</div>
          <TextField source="locale" />
        </div>
        <div style={styles.df}>
          <div style={styles.label}>- Timezone:</div>
          <TextField source="timezone" />
        </div>
      </div>

      {canUpdate('users') && (
        <div style={styles.dangerZone}>
          <strong style={{ color: Colors.Red.primary }}>⚠️ Danger zone</strong>
          <div style={styles.df}>
            <div style={styles.label}>- Command Center access:</div>
            {commandCenterRoleHumanized}
            {canEditCommandCenterRole && (
              <UpdateFieldDialog
                resource="users"
                record={user}
                type="select"
                field="commandCenterRole"
                buttonLabel="edit"
                buttonVariant="outlined"
                selectOptions={commandCenterRolesOptions}
                hint="This will change the user's command center role"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.normal,
    fontSize: FontStyle.sizeMedium,
    marginBottom: 240,
  },
  signUpInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle.normal,
    backgroundColor: Colors.Grey[100],
    borderRadius: BorderStyle.Radius.normal,
    gap: SpacingStyle.normal,
  },
  df: {
    display: 'flex',
    gap: SpacingStyle.small,
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  label: {
    fontWeight: 'bold',
  },
  profileCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
    maxWidth: 500,
    margin: `${SpacingStyle.small}px 0`,
    padding: SpacingStyle.normal,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Background.light,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  profileCardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: '600',
    color: Colors.Grey[600],
  },
  media: {
    borderRadius: BorderStyle.Radius.normal,
    height: 300,
    width: 200,
    objectFit: 'cover',
    backgroundColor: Colors.Grey[100],
  },
  tag: {
    padding: `${SpacingStyle[4]}px ${SpacingStyle.small}px`,
    borderRadius: BorderStyle.Radius.big,
    backgroundColor: Colors.Grey[100],
    fontSize: FontStyle.sizeVerySmall,
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    color: Colors.OffBlack.primary,
  },
  dangerZone: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.normal,
    alignItems: 'flex-start',
    marginTop: SpacingStyle.big,
    padding: SpacingStyle.normal,
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Red.primary}`,
  },
};

export default ProfileTab;
