import React from 'react';
import { FormDataConsumer } from 'react-admin';

import IosNotificationPreview from './ios_notification_preview';
import NotificationCenterPreview from './notification_center_preview';
import ToastNotificationPreview from './toast_notification_preview';

type NotificationsPreviewProps = {
  imageUrl: string;
};
export const NotificationsPreview = ({ imageUrl }: NotificationsPreviewProps) => (
  <FormDataConsumer>
    {({ formData }) => (
      <div
        style={{
          display: 'flex',
          marginTop: 40,
          width: '100%',
          maxWidth: 350 * 3,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {formData.sendPushNotification && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <strong>Push Notification</strong>
            <IosNotificationPreview
              label="🇺🇸"
              title={formData.titleEN || 'Title EN'}
              body={formData.descriptionEN}
            />
            <IosNotificationPreview
              label="🇫🇷"
              title={formData.titleFR || 'Title FR'}
              body={formData.descriptionFR}
            />
          </div>
        )}
        {formData.displayAsToast && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <strong>In-App Notification</strong>
            <ToastNotificationPreview
              label="🇺🇸"
              title={formData.titleEN || 'Title EN'}
              body={formData.descriptionEN || 'Type something in...'}
              emoji={formData.badgeEmoji}
            />
            <ToastNotificationPreview
              label="🇫🇷"
              title={formData.titleFR || 'Title FR'}
              body={formData.descriptionFR || 'Type something in...'}
              emoji={formData.badgeEmoji}
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <strong>Notification Center</strong>
          <NotificationCenterPreview
            notifications={[
              {
                title: formData.titleEN || 'Titre EN',
                body: formData.descriptionEN || 'Type something in...',
                badgeEmoji: formData.badgeEmoji,
                imageUrl,
              },
              {
                title: formData.titleFR || 'Titre FR',
                body: formData.descriptionFR || 'Type something in...',
                badgeEmoji: formData.badgeEmoji,
                imageUrl,
              },
            ]}
          />
        </div>
      </div>
    )}
  </FormDataConsumer>
);
