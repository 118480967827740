'use strict';

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var countries = require('../countries.json');

var MAGIC_NUMBER = 127462 - 65;
var CODE_RE = /^[a-z]{2}$/i;
var NAME_RE = /^.{2,}$/;
var FLAG_RE = /\uD83C[\uDDE6-\uDDFF]/;

function fuzzyCompare(input, name) {
  name = name.toLowerCase(); // Cases like:
  //    "Vatican" <-> "Holy See (Vatican City State)"
  //    "Russia"  <-> "Russian Federation"

  if (name.includes(input) || input.includes(name)) {
    return true;
  } // Cases like:
  //    "British Virgin Islands" <-> "Virgin Islands, British"
  //    "Republic of Moldova"    <-> "Moldova, Republic of"


  if (name.includes(',')) {
    var reversedName = name.split(', ').reverse().join(' ');

    if (reversedName.includes(input) || input.includes(reversedName)) {
      return true;
    }
  }

  return false;
}

function isCode(code) {
  code = code.toUpperCase();
  return countries[code] ? code : undefined;
}

function nameToCode(name) {
  if (!name || !NAME_RE.test(name)) {
    return;
  }

  name = name.trim().toLowerCase(); // Look for exact match
  // NOTE: normal loop to terminate ASAP

  for (var _code in countries) {
    if ({}.hasOwnProperty.call(countries, _code)) {
      var names = countries[_code];

      if (!Array.isArray(names)) {
        names = [names];
      }

      var _iterator = _createForOfIteratorHelper(names),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var n = _step.value;

          if (n.toLowerCase() === name) {
            return _code;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  } // Look for inexact match
  // NOTE: .filter() to aggregate all matches


  var matches = Object.keys(countries).filter(function (code) {
    var names = countries[code];

    if (!Array.isArray(names)) {
      names = [names];
    }

    var _iterator2 = _createForOfIteratorHelper(names),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _n = _step2.value;

        if (fuzzyCompare(name, _n)) {
          return true;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    return false;
  }); // Return only when exactly one match was found
  //   prevents cases like "United"

  if (matches.length === 1) {
    return matches[0];
  }
}

function codeToName(code) {
  if (!code || !CODE_RE.test(code)) {
    return;
  }

  var names = countries[code.toUpperCase()];

  if (Array.isArray(names)) {
    return names[0];
  }

  return names;
}

function codeToFlag(code) {
  if (!code || !CODE_RE.test(code)) {
    return;
  }

  code = isCode(code);

  if (!code) {
    return;
  }

  if (String && String.fromCodePoint) {
    return String.fromCodePoint.apply(String, _toConsumableArray(_toConsumableArray(code).map(function (c) {
      return MAGIC_NUMBER + c.charCodeAt(0);
    })));
  }
}

function flagToCode(flag) {
  if (!flag || !FLAG_RE.test(flag)) {
    return;
  }

  return isCode(_toConsumableArray(flag).map(function (c) {
    return c.codePointAt(0) - MAGIC_NUMBER;
  }).map(function (c) {
    return String.fromCharCode(c);
  }).join(''));
} // Takes either emoji or full name


function code(input) {
  return flagToCode(input) || nameToCode(input);
} // Takes either code or full name


function flag(input) {
  if (!CODE_RE.test(input) || input === 'UK') {
    input = nameToCode(input);
  }

  return codeToFlag(input);
} // Takes either emoji or code


function name(input) {
  if (FLAG_RE.test(input)) {
    input = flagToCode(input);
  }

  return codeToName(input);
}

module.exports = {
  MAGIC_NUMBER: MAGIC_NUMBER,
  CODE_RE: CODE_RE,
  NAME_RE: NAME_RE,
  FLAG_RE: FLAG_RE,
  code: code,
  flag: flag,
  name: name,
  countries: countries,
  isCode: isCode,
  fuzzyCompare: fuzzyCompare,
  codeToName: codeToName,
  codeToFlag: codeToFlag,
  nameToCode: nameToCode,
  flagToCode: flagToCode
};