/**
 * Renders a preview of an iOS notification
 */
import React from 'react';

import ellipsis from '@services/ellipsis';

type IOSNotificationPreviewProps = {
  label?: string;
  title?: string;
  body?: string;
};
export default ({ label, title, body }: IOSNotificationPreviewProps) => {
  return (
    <div style={styles.iphone}>
      <small style={styles.iphoneLabel}>{label || '🔔'}</small>
      <div style={styles.iphoneNotification}>
        <img
          alt="iPhone background"
          style={styles.iphoneNotificationImage}
          src="https://res.cloudinary.com/hsiz9ovy1/image/upload/v1694737404/in_app_notifications/app-ios_bdz8wz.png"
        />
        <div style={styles.iphoneNotificationText}>
          <div style={styles.iphoneNotificationTitle}>{title || 'chilli'}</div>
          <div style={styles.iphoneNotificationBody}>
            {body ? (
              ellipsis(body, 90)
            ) : (
              <div style={{ color: '#bbb' }}>Type something in...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  iphone: {
    width: 320,
    marginBottom: 20,
    backgroundColor: '#ddd',
    backgroundImage:
      'url(https://149493502.v2.pressablecdn.com/wp-content/uploads/2020/06/iOS-14-default-wallpaper-for-iPhone.jpg)',
    backgroundSize: 'cover',
    borderRadius: 18,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iphoneLabel: {
    marginTop: 10,
  },
  iphoneNotification: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    margin: '20px 0',
    padding: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 18,
  },
  iphoneNotificationImage: {
    width: 30,
    height: 30,
    borderRadius: 10,
    objectFit: 'cover',
  },
  iphoneNotificationText: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '12px',
    letterSpacing: 0,
    lineHeight: '1.1em',
  },
  iphoneNotificationTitle: {
    width: '220px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
