import { FocusOnValue } from "./gravity/qualifiers/focusOn/FocusOnValue.js";
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects birds
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function bird() {
    return new FocusOnValue('bird');
}
/**
 * @summary qualifier
 * @description Detects dogs
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function dog() {
    return new FocusOnValue('dog');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects cats
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function cat() {
    return new FocusOnValue('cat');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects microwaves
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function microwave() {
    return new FocusOnValue('microwave');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects refrigerators
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function refrigerator() {
    return new FocusOnValue('refrigerator');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects bottles
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function bottle() {
    return new FocusOnValue('bottle');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects sinks
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function sink() {
    return new FocusOnValue('sink');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects skateboards
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function skateboard() {
    return new FocusOnValue('skateboard');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects people
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function person() {
    return new FocusOnValue('person');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects the largest face in an image with the Advanced Facial Attribute Detection add-on and makes it the focus of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function advancedFace() {
    return new FocusOnValue('adv_face');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects all faces in an image with the Advanced Facial Attribute Detection add-on and makes them the focus of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function advancedFaces() {
    return new FocusOnValue('adv_faces');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects all eyes in an image with the Advanced Facial Attribute Detection add-on and makes them the focus of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function advancedEyes() {
    return new FocusOnValue('adv_eyes');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects the largest face in the asset and makes it the focus of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function face() {
    return new FocusOnValue('face');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects all the faces in the asset and makes them the focus of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function faces() {
    return new FocusOnValue('faces');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects all the faces in the asset and makes them the focus of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function background() {
    return new FocusOnValue('background');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects aeroplane
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function aeroplane() {
    return new FocusOnValue('aeroplane');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects bicycle
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function bicycle() {
    return new FocusOnValue('bicycle');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects boat
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function boat() {
    return new FocusOnValue('boat');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects bus
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function bus() {
    return new FocusOnValue('bus');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects car
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function car() {
    return new FocusOnValue('car');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects chair
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function chair() {
    return new FocusOnValue('chair');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects cow
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function cow() {
    return new FocusOnValue('cow');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects diningtable
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function diningtable() {
    return new FocusOnValue('diningtable');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects horse
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function horse() {
    return new FocusOnValue('horse');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects motorbike
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function motorbike() {
    return new FocusOnValue('motorbike');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects pottedplant
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function pottedplant() {
    return new FocusOnValue('pottedplant');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects sheep
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function sheep() {
    return new FocusOnValue('sheep');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects sofa
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function sofa() {
    return new FocusOnValue('sofa');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects train
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function train() {
    return new FocusOnValue('train');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detects tvmonitor
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function tvmonitor() {
    return new FocusOnValue('tvmonitor');
}
/**
 * @summary qualifier
 * @memberOf Qualifiers.FocusOn
 * @description Detect all text elements in an image using the {@link https://cloudinary.com/documentation/ocr_text_detection_and_extraction_addon|OCR Text Detection and Extraction add-on} and use the detected bounding box coordinates as the basis of the transformation.
 * @return {Qualifiers.FocusOn.FocusOnValue} FocusOnValue
 */
function ocr() {
    return new FocusOnValue('ocr_text');
}
/**
 * @memberOf Qualifiers
 * @namespace FocusOn
 * @see Visit {@link Qualifiers.Gravity|Gravity} for an example
 */
const FocusOn = {
    person,
    cat,
    microwave,
    refrigerator,
    skateboard,
    bird,
    bottle,
    dog,
    sink,
    face,
    train,
    sofa,
    sheep,
    pottedplant,
    horse,
    faces,
    cow,
    bus,
    boat,
    advancedEyes,
    advancedFace,
    advancedFaces,
    aeroplane,
    background,
    bicycle,
    car,
    chair,
    diningtable,
    tvmonitor,
    motorbike,
    ocr
};
export { FocusOnValue, FocusOn, person, cat, microwave, refrigerator, skateboard, bird, bottle, dog, sink, face, train, sofa, sheep, pottedplant, horse, faces, cow, bus, boat, advancedEyes, advancedFace, advancedFaces, aeroplane, background, bicycle, car, chair, diningtable, tvmonitor, motorbike, ocr };
