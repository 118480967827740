import * as React from 'react';
import {
  DeleteButton,
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ImageInput,
  required,
  ImageField,
} from 'react-admin';

import { useRoleBasedPermissions } from '@hooks/useRoleBasedPermissions';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import TimezoneInput from '@components/inputs/timezone_input';
import LocaleInput from '@components/inputs/locale_input';
import CountryInput from '@components/inputs/country_input';
import UrlInput from '@components/inputs/url_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.id}/show`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

const UserCreateToolbar = ({ ...props }) => {
  const { canCreate, canDelete } = useRoleBasedPermissions();
  return (
    <Toolbar {...props}>
      {canCreate('users') && <SaveButton />}
      {canDelete('users') && <DeleteButton />}
    </Toolbar>
  );
};

export const UserCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm toolbar={<UserCreateToolbar />}>
      <TextInput source="username" fullWidth required />
      <TextInput source="firstname" fullWidth required />
      <TextInput source="lastname" fullWidth required />
      <TextInput source="phoneNumber" fullWidth />
      <ImageInput
        label="Avatar"
        source="picture"
        accept="image/*"
        maxSize={5000000}
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput
        source="bio"
        fullWidth
        multiline
        placeholder="255 char max"
        inputProps={{ maxLength: 255 }}
        required
      />
      <TextInput
        source="occupation"
        fullWidth
        multiline
        placeholder="80 char max"
        inputProps={{ maxLength: 80 }}
      />
      <LocaleInput source="locale" label="Locale" required />
      <TimezoneInput source="timezone" label="Timezone" required />
      <TextInput source="city" fullWidth />
      <TextInput source="region" fullWidth />
      <CountryInput source="country" label="Country" required />

      <hr style={{ width: '100%' }} />

      <UrlInput
        source="instagramHandle"
        fullWidth
        label="Instagram URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="tiktokHandle"
        fullWidth
        label="Tiktok URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="twitterHandle"
        fullWidth
        label="X (Twitter) URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="mastodonHandle"
        fullWidth
        label="Mastodon URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="blueskyHandle"
        fullWidth
        label="Bluesky URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="websiteUrl"
        fullWidth
        label="Website URL"
        inputProps={{ maxLength: 255 }}
      />
    </SimpleForm>
  </Create>
);
