import * as React from 'react';
import {
  SimpleForm,
  Create,
  NumberInput,
  TextInput,
  required,
  maxLength,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import IOSNotificationPreview from '@models/in_app_notifications/components/ios_notification_preview';

import UserTransactionTypeInput from './components/user_transaction_type_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show/transactions`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const UsersTransactionsCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        suggestionLimit={100}
      />
      <UserTransactionTypeInput />

      <NumberInput source="coinsAmount" required max={1000} min={0} step={10} fullWidth />

      <BooleanInput source="sendPushNotification" />

      <TextInput
        source="message"
        inputProps={{ maxLength: 200 }}
        validate={[required(), maxLength(200)]}
        fullWidth
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.sendPushNotification && (
            <IOSNotificationPreview
              title={`${formData.coinsAmount || 'new'} coins earned`}
              body={formData.message || '⚠️ YOU MUST WRITE A MESSAGE'}
            />
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
