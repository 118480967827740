import { GravityQualifier } from "../GravityQualifier.js";
/**
 * @description The class for the XYCenter Gravity builder
 * @memberOf Qualifiers.Gravity
 * @extends {Qualifiers.Gravity.GravityQualifier}
 */
class XYCenterGravity extends GravityQualifier {
    constructor() {
        // Required due to https://github.com/microsoft/TypeScript/issues/13029
        /* istanbul ignore next */
        super('xy_center');
    }
}
export { XYCenterGravity };
