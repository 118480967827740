import React, { useCallback, useState } from 'react';
import {
  Button,
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';

import Callout from '@components/callout';
import Favicon from '@components/svgs/logos/favicon';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

import { CountrySelect } from './components/country';
import EmailRecipientsInput from './components/email_recipient/email_recipients_input';
import { validateEmailContent } from './create';

const redirectAfterEdit = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show`;

const EditActions = () => {
  const params = useParams();
  const record = useRecordContext();
  let actionId = params?.actionId ? params.actionId : record?.actionId;
  return (
    <ResourceTitleActionBar
      mode="edit"
      actions={
        <Button
          href={`#/actions/${actionId}/show`}
          startIcon={<Favicon width={15} height={12} />}
          label="Back to Action"
          variant="outlined"
        />
      }
    />
  );
};

/**
 * @param {*} props
 */
const EditToolbar = ({ ...props }) => {
  const record = useRecordContext(props);
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton
        redirect={(basePath: any) => redirectAfterEdit(basePath, record.id, record)}
      />
    </Toolbar>
  );
};

const Form = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Re-generates the summaries of the action.
   */
  const handleReGenerateSummaries = useCallback(async () => {
    if (!record?.id) {
      return;
    }
    try {
      setIsLoading(true);
      await dataProvider.reGenerateActionSummaries({
        actionId: record.id,
      });
      refresh();
    } finally {
      setIsLoading(false);
    }
  }, [dataProvider, record, refresh]);

  return (
    <SimpleForm toolbar={<EditToolbar />}>
      <h3>Location</h3>
      <CountrySelect />

      <br />
      <h3>Email</h3>
      <Callout emoji="🤖" backgroundColor="grey">
        GPT will rewrite both the <strong>subject</strong> and <strong>body</strong> of
        the email based on the <strong>body</strong> of the email and the user's values
        before sending it.
      </Callout>
      <div
        style={{
          width: '100%',
          backgroundColor: Colors.Grey[100],
          paddingLeft: SpacingStyle.small,
          paddingRight: SpacingStyle.small,
          borderRadius: BorderStyle.Radius.small,
        }}
      >
        <EmailRecipientsInput source="to" isRequired />
        <EmailRecipientsInput source="cc" />
        <EmailRecipientsInput source="bcc" />
        <TextInput
          source="subject"
          required
          fullWidth
          validate={validateEmailContent}
          helperText={false}
        />
        <TextInput
          source="body"
          label="Body"
          required
          multiline
          fullWidth
          validate={validateEmailContent}
          minRows={10}
        />
      </div>

      <br />
      <h3>Summaries</h3>
      <Callout emoji="📝" backgroundColor="grey" style={{ width: '100%', flex: 1 }}>
        Summaries are displayed to chilli members only.
        <br />
        Those have been autogenerated based on the content of the email.
      </Callout>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <Button
          label="Re-generate summaries"
          startIcon={<SyncIcon />}
          variant="contained"
          size="small"
          onClick={handleReGenerateSummaries}
          disabled={isLoading}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: SpacingStyle.small,
          width: '100%',
        }}
      >
        <TextInput
          label="Summary 🇺🇸"
          source="summaryEN"
          required
          multiline
          fullWidth
          disabled={isLoading}
        />
        <TextInput
          label="Summary 🇫🇷"
          source="summaryFR"
          required
          multiline
          fullWidth
          disabled={isLoading}
        />
      </div>
    </SimpleForm>
  );
};

export const ActionCtaEmailEdit = () => {
  return (
    <Edit
      redirect={redirectAfterEdit}
      mutationMode="pessimistic"
      actions={<EditActions />}
    >
      <Form />
    </Edit>
  );
};
